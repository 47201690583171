<template>
  <v-container fluid class="pa-0 ma-0">

    <!-- 상단 툴바 -->
    <v-toolbar dense flat>
      <v-btn text small @click="goList">
        <v-icon small>mdi-view-list</v-icon>
        목록
      </v-btn>
      <v-btn
        v-show="!notEditable"
        text
        small
        color="primary"
        @click="admit">
        <v-icon small>mdi-check-underline-circle</v-icon>
        승인
      </v-btn>
      <v-btn
        v-show="!notEditable"
        text
        small
        color="warning"
        @click="turnBack">
        <v-icon small>mdi-backup-restore</v-icon>
        반려
      </v-btn>
      <v-btn
        v-show="!notEditable"
        text
        small
        color="error"
        @click="disapprove">
        <v-icon small>mdi-cancel</v-icon>
        불허
      </v-btn>
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on }">
          <v-icon
            v-show="!notEditable"
            small
            v-on="on"
            class="ml-2"
          >mdi-help-circle-outline</v-icon>
        </template>
        <span>반려 - 부족부분을 보충하여 다시 상신 가능함<br>불허 - 해당 결재는 불가함</span>
      </v-tooltip>
      <!-- !! 반려나 오류로 인한 수정버튼 활성화 처리 -->
      <v-btn
        v-show="useEditBtn"
        text
        small
        color="primary"
        @click="moveEdit">
        <v-icon small>mdi-pencil</v-icon>
        수정
      </v-btn>
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on }">
          <v-icon
            v-show="useEditBtn"
            small
            v-on="on"
            class="ml-2"
          >mdi-help-circle-outline</v-icon>
        </template>
        <span>수정버튼을 클릭하면 결재가 작성함으로 이동하므로 재상신 할 수 있습니다.</span>
      </v-tooltip>

      <v-spacer></v-spacer>
      <v-btn text small
        class="hidden-xs-only"
        @click.stop="pdfgen"
      >
        <v-icon small>mdi-download</v-icon>
        저장
      </v-btn>
    </v-toolbar>

    <v-divider></v-divider>

    <!-- 컨텐츠 영역 -->
    <v-card
      tile
      :elevation="0"
      class="ma-0 pa-0"
    >
      <v-row
        no-gutters
      >
        <v-col v-if="!isId" cols="12">
          <v-alert
            prominent
            type="warning"
            class="ma-4 pa-10"
          >존재하지 않는 데이터 입니다</v-alert>
        </v-col>
        <v-col v-else cols="12" class="ma-0 pa-0">
          <v-row
            no-gutters
          >
            <v-col cols="12" class="ma-0 pa-0">
              <v-toolbar dense flat>
                <v-chip
                  label
                  :color="currStatusColor"
                  dark
                  class="mr-2"
                >
                  <v-icon small class="mr-1">{{ currStatusIcon }}</v-icon>
                  {{ article.gubun1 }}
                </v-chip>
                <v-icon small
                  v-if="article.isStar"
                  color="amber darken-1"
                >mdi-star</v-icon>
                <span class="text-subtitle-1">
                  {{ article.code ? `(${article.code}) ` : '' }}
                </span>
                <span class="text-subtitle-1">
                  {{ article.subject }}
                </span>
                <span class="mx-2 text-caption grey--text">
                  {{ strDateFormat2(article.updatedAt) }} 작성
                </span>
                <v-spacer></v-spacer>
                <!-- <v-icon>mdi-account</v-icon> -->
                <!-- !! 히스토리 뷰 팝업 -->
                <v-btn
                  v-show="article.depth > 1"
                  text
                  small
                  color="primary"
                  @click="viewHistoryPop"
                >
                  <v-icon small>mdi-history</v-icon>
                  history
                </v-btn>
              </v-toolbar>
              <v-divider></v-divider>
            </v-col>

            <!-- 오류 메시지 --->
            <v-col v-show="article.status === 6" cols="12" class="hidden-xs-only ma-0 pa-0">
              <v-alert
                text
                dense
                outlined
                color="error"
                icon="error"
                class="ma-3"
              >
                <div class="text-body-2">
                  결재자가 공유해제되는 경우 결재는 오류상태로 변경됩니다. 기안자는 결재를 수정하여 재상신 할 수 있습니다.
                </div>
              </v-alert>
              <v-divider></v-divider>
            </v-col>

            <!-- 좌측 컨텐츠 시작 --->
            <v-col cols="12" md="7" xs="12" sm="12">
              <v-container fluid class="ma-0 pa-0">
                <v-row
                  align="center"
                  justify="center"
                  no-gutters
                >
                  <!-- !! 연계정보 -->
                  <v-col cols="12" class="pt-3 pb-0 px-2">
                    <span class="text-subtitle-2 grey--text text--darken-1 mr-3">연계정보</span>
                  </v-col>
                  <v-col cols="12" class="pt-1 pb-1 px-1">
                    <v-alert
                      v-show="article.rId && article.rType"
                      text
                      color="indigo"
                      dense
                      class="text-body-2 ma-0 px-2 pt-2 pb-1"
                      :class="isDelRelation ? 'grey--text text--darken-1' : ''"
                    >
                      <v-icon
                        small
                        :color="isDelRelation ? 'grey darken-1' : 'indigo'"
                        class="mx-1 mb-1"
                      >mdi-link-variant</v-icon>
                      {{ isDelRelation ? '[삭제됨] ' : '' }}{{ relData }}
                      <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            small
                            :color="isDelRelation ? 'grey darken-1' : 'primary lighten-1'"
                            @click="gotoDetail"
                            class="ml-1"
                          >mdi-launch</v-icon>
                        </template>
                        <span>바로가기</span>
                      </v-tooltip>
                    </v-alert>
                  </v-col>
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>

                  <!-- !! 첨부파일 + 공유링크 -->
                  <v-col cols="12" class="pt-3 pb-0 px-2">
                    <span class="text-subtitle-2 grey--text text--darken-1">첨부파일 + 공유링크</span>
                  </v-col>
                  <v-col cols="12" class="py-2 px-2">
                    <!-- 첨부파일 목록 -->
                    <div
                      v-for="file in attachedFiles"
                      :key="file.id"
                      class="my-1 text-body-2"
                    >
                      <v-hover v-slot:default="{ hover }" transition="fade-transition">
                        <div class="ma-0 pa-0">
                          <div>
                            <v-icon
                              :color="file.isAnotherWrite ? 'purple' : ''"
                              x-small
                            >
                              {{ file.isAnotherWrite ? 'mdi-ray-start-arrow' : 'mdi-subdirectory-arrow-right' }}
                            </v-icon>
                            <v-icon v-show="file.type === 36" small :color="mdiDownloadButtonColor">mdi-paperclip</v-icon>
                            <v-icon v-show="file.type === 38" small :color="mdiLinkShareIconColor">mdi-link-plus</v-icon>
                            <!-- !! 첨부파일, 공유링크 카테고리 태그(attach file category tag) 컴포넌트 -->
                            <attach-file-category-tag
                              v-show="file.type === 36 || file.type === 38"
                              :caption="file.gubun2"
                            ></attach-file-category-tag>
                            {{ file.str1 }}
                            <span class="ml-1 text-caption grey--text">
                              ({{ strDateFormat2(file.createdAt) }} {{ file.wname }})
                            </span>
                            <!-- 사용안함:[2021.9.1] 첨부파일 다운로드 -->
                            <!-- <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  v-show="hover && file.type === 36"
                                  v-on="on"
                                  small
                                  right
                                  :color="mdiDownloadButtonColor"
                                  class="ml-3"
                                  @click="fileDownload(file)"
                                >mdi-cloud-download-outline</v-icon>
                              </template>
                              <span>다운로드</span>
                            </v-tooltip> -->
                            <!-- 첨부파일 바로보기 -->
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  v-show="hover && file.type === 36"
                                  v-on="on"
                                  small
                                  right
                                  :color="mdiOpenFileButtonColor"
                                  class="ml-3"
                                  @click="fileDirectView(file)"
                                >mdi-eye-outline</v-icon>
                              </template>
                              <span>바로보기</span>
                            </v-tooltip>
                            <!-- 공유링크 바로가기 -->
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  v-show="hover && file.type === 38"
                                  v-on="on"
                                  small
                                  right
                                  :color="mdiPlusBoxOutlineButtonColor"
                                  class="ml-3"
                                  @click="shareLinkDirectGo(file)"
                                >mdi-launch</v-icon>
                              </template>
                              <span>바로가기</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </v-hover>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>

                  <v-col cols="12" class="pt-3 pb-0 px-2">
                    <span class="text-subtitle-2 grey--text text--darken-1">내용</span>
                  </v-col>
                  <!-- !![2021.4.23]내용 들어가는 영역 수정: 읽기 편한 사이즈로 글씨 조정 -->
                  <v-col cols="12" xs="12" sm="12">
                    <v-card
                      elevation="0"
                      class="mx-1 my-0 px-1 py-3"
                    >
                      <v-card-text class="text--center grey--text text--darken-4 px-3 py-0">
                        <div
                          id="vue2editorViewer"
                          style="font-size: 0.975rem;font-weight: 400;line-height: 1.85;"
                          v-html="article.content"
                        ></div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" class="my-12 py-5">
                  </v-col>

                </v-row>
              </v-container>
            </v-col>

            <v-divider vertical class="ma-0 pa-0"></v-divider>

            <!-- 우측 컨텐츠 시작 --->
            <v-col>
              <v-container fluid class="ma-0 pa-0">
                <v-row
                  align="center"
                  justify="center"
                  no-gutters
                >
                  <!-- !! 결재자 -->
                  <v-col cols="12" class="pt-3 pb-0 px-2">
                    <span class="text-subtitle-2 grey--text text--darken-1">결재자</span>
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-4 px-2">
                    <template v-for="(approver, i) in approvers">
                      <v-row
                        :key="i"
                        no-gutters
                      >
                        <v-col cols="12" xs="12" sm="12">
                          <!-- 결재중인 경우만 ㅅ -->
                          <v-card
                            :elevation="article.tern === (i + 1) && article.status < 3 ? '1' : '0'"
                            :outlined="article.tern === (i + 1) && article.status < 3"
                            :class="article.tern === (i + 1) && article.status < 3 ? 'ma-1 pa-0' : 'ma-0 pa-0'"
                          >
                            <v-avatar tile size="20" color="blue">
                              <span class="white--text text-body-2">{{ i }}</span>
                            </v-avatar>
                            <v-list dense class="ma-0 pa-0">
                              <v-list-item
                                v-for="item in approver.items"
                                :key="item.email"
                                class="ma-0 py-0"
                              >
                                <span class="text-body-2">
                                  <v-avatar
                                    v-show="item.email === $store.state.ui.email"
                                    color="amber"
                                    class="mr-1"
                                    size="20"
                                  >
                                    <v-icon small color="primary">mdi-account-circle</v-icon>
                                  </v-avatar>
                                  <span :class="item.result === 4 ? 'grey--text text--lighten-1 text-decoration-line-through' : ''">{{ item.name }}</span>
                                  <span class="text-caption grey--text text--darken-1">
                                    {{ item.rank }}
                                  </span>
                                  <!-- !! 기안자의 처리상태 -->
                                  <v-chip
                                    v-show="item.tern === 1"
                                    x-small label class="mx-1 px-2 text-caption"
                                    color="primary"
                                  >
                                    상신
                                  </v-chip>
                                  <!-- !! 기안자 이외의 현재 처리상태 -->
                                  <v-chip
                                    v-show="item.tern !== 1"
                                    x-small label class="mx-1 px-2 text-caption"
                                    :color="resultColor[item.result]"
                                  >
                                    {{ resultTitle[item.result] }}
                                  </v-chip>
                                  <span v-show="item.result !== 0" class="mx-0 text-caption">
                                    {{ strDateFormat2($moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss')) }}
                                  </span>
                                  <v-tooltip bottom color="grey darken-3">
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        v-show="item.txt1"
                                        v-on="on"
                                        small text
                                      >
                                        <v-icon small>mdi-history</v-icon>
                                      </v-btn>
                                    </template>
                                    <div id="vue2editorViewer" class="text-body-2 pa-1" style="max-width: 520px;" v-html="item.txt1"></div>
                                    <!-- <span>히스토리</span> -->
                                  </v-tooltip>
                                  <!-- <span v-show="item.email === $store.state.ui.email" class="mx-2 text-caption">
                                    현재 유저
                                  </span> -->
                                </span>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-col>
                      </v-row>
                    </template>
                  </v-col>
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>
                  <!-- !! 회람자 -->
                  <v-col cols="12" class="pt-3 pb-0 px-2">
                    <span class="text-subtitle-2 grey--text text--darken-1">회람자</span>
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-4 px-2">
                    <!-- 회람자 목록 -->
                    <v-list dense class="ma-0 pa-0">
                      <v-list-item
                        v-for="(viewer, i) in viewers"
                        :key="i"
                        class="ma-0 py-0"
                      >
                        <span class="text-body-2">
                          {{ viewer.name }}
                          <span class="text-caption grey--text text--darken-1">
                            {{ viewer.rank }}
                          </span>
                        </span>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>
                  <!-- !! 의견(댓글) -->
                  <v-col cols="12" class="pt-3 pb-0 px-2">
                    <span class="text-subtitle-2 grey--text text--darken-1">의견</span>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          small
                          :color="mdiPlusBoxOutlineButtonColor"
                          class="ml-3"
                          @click="showCommentPop"
                        >mdi-comment-text-outline</v-icon>
                      </template>
                      <span>등록</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-4 px-2">
                    <!-- 의견 목록 -->
                    <div
                      v-for="(comment, i) in comments"
                      :key="i"
                      class="my-1 text-body-2"
                    >
                      <v-hover v-slot:default="{ hover }" transition="fade-transition">
                        <div class="ma-0 pa-0">
                          <div>
                            <v-icon
                              :color="comment.isAnotherWrite ? 'purple' : ''"
                              x-small
                            >
                              {{ comment.isAnotherWrite ? 'mdi-ray-start-arrow' : 'mdi-subdirectory-arrow-right' }}
                            </v-icon>
                            {{ comment.str1 }}
                            <span class="text-caption grey--text">({{ strDateFormat2(comment.createdAt) }} {{ comment.wname }})</span>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  v-show="hover && !comment.isAnotherWrite"
                                  v-on="on"
                                  small
                                  right
                                  :color="mdiTrashCanOutlineButtonColor"
                                  class="ml-3"
                                  @click="deleteComment(comment.id)"
                                >mdi-trash-can-outline</v-icon>
                              </template>
                              <span>삭제</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </v-hover>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <!-- !! 첨부파일 다운로드시 오버레이 -->
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!-- 댓글 팝업 -->
    <add-comment-pop ref="addCommentPop"></add-comment-pop>
    <!-- 히스토리 팝업 -->
    <view-history-pop ref="viewHistoryPop"></view-history-pop>

  </v-container>
</template>

<script>
// @: utils
import sleep from '@/lib/sleep'

// @: filters
import strDateFormat2 from '@/filters/strDateFormat2'

// @: popups
import addCommentPop from '@/components/eawriting/addCommentPop'
import viewHistoryPop from '@/components/eawriting/viewHistoryPop'

// @: pdfmake view
import {
  pdfMake,
  pdfViewStyle,
  menuTitle,
  rmTagReturnArray,
  viewType1,
  viewType2 } from '@/lib/pdfmakeView'

// @: tag components
import attachFileCategoryTag from '@/components/tags/attachFileCategoryTag' // 첨부파일,공유링크 카테고리 태그

// !! 현재 dir
const currDir = 'ealist'

export default {
  components: {
    addCommentPop,
    viewHistoryPop,
    attachFileCategoryTag
  },

  data: () => ({
    // 구분: 아이콘 컬러
    mdiPlusBoxOutlineButtonColor: 'info darken-1',
    mdiListEditButtonColor: 'indigo accent-2',
    mdiTrashCanOutlineButtonColor: 'warning darken-1',
    mdiUploadButtonColor: 'primary lighten-1',
    mdiDownloadButtonColor: 'success lighten-1',
    mdiLinkShareIconColor: 'cyan darken-2',
    mdiOpenFileButtonColor: 'orange lighten-1',
    // 구분: 게시물 데이터
    articleId: 0,
    article: {},
    // 구분: 현 로그인 유저의 현 결재건에 대한 ealine 정보
    userLineInfo: null,
    // 구분: '승인','반려','불허' 버튼을 사용할 수 있나 여부 - 로그인 유저가 현재 tern 인 경우만 가능
    notEditable: false,
    // 구분: '수정' 버튼 사용여부 - '반려'일때 기안자만 사용가능
    useEditBtn: false,
    // 구분: 결재자와 회람자 배열
    approvers: [],
    viewers: [],
    // 구분: 현재 결재컨텐츠 상태에 따른 색상 변화
    currStatusColor: 'primary',
    statusColors: {
      1: 'brown darken-2', // 임시저장
      2: 'primary', // 결재중
      3: 'success', // 최종승인
      4: 'error', // 불허
      5: 'warning', // 반려
      6: 'error' // 오류
    },
    // 구분: 결재라인 결과에 따른 색상
    resultColor: {
      0: 'grey', // 대기
      1: 'success', // 승인
      2: 'warning', // 반려
      3: 'error', // 불허
      4: 'error' // 오류
    },
    // 구분: 결재라인 결과에 따른 타이틀
    resultTitle: {
      0: '대기',
      1: '승인',
      2: '반려',
      3: '불허',
      4: '오류' // '오류' 보다는 '공유해제'가 더 나을려나?
    },
    // 구분: 현재 상태 아이콘
    currStatusIcon: 'mdi-fast-forward-outline',
    // 구분: 아이콘 집합객체
    statusIcon: {
      1: 'mdi-content-save-cog', // 임시저장
      2: 'mdi-fast-forward-outline', // 결재중
      3: 'mdi-check-underline-circle', // 최종승인
      4: 'mdi-cancel', // 불허
      5: 'mdi-backup-restore', // 반려
      6: 'mdi-alert-circle' // 오류
    },
    // 구분: 댓글 목록
    comments: [],
    // 구분: 첨부파일 목록(첨부파일 + 공유링크)
    attachedFiles: [],
    // 구분: 첨부파일 다운로드 로딩시 오버레이
    overlay: false,
    // 구분: 로그인 유저가 속한 teamId 배열
    myTids: [],
    // 구분: pdf
    pdfDoc: {
      styles: null,
      defaultStyle: null,
      // pageOrientation: 'landscape', // !! 리스트는 가로로
      pageSize: 'A4',
      pageMargins: [ 30, 25, 30, 25 ],
      content: []
    },
    // 구분: 존재하는 아이디 인지 여부
    isId: true,
    // 구분: 2021.2.22 연계데이터가 있고 연계데이터가 삭제된경우 true
    isDelRelation: false,
    // 구분:[2021.7.15] 연계데이터 정보
    relData: ''
  }),

  watch: {
    // !!중요: /ealist 내부에서 라우트 변화를 감지하여 동적으로 변경해준다.
    '$route' (to, from) {
      // 사용안함:[2021.10.22 리다이렉트를 제대로 해주지 못해서 폐기]
      // 복잡하지 않고 단순하게 아래처럼.
      // this.articleId = Number(to.params.id)
      // 넘어온 id로 해당 게시물 정보를 가져와야 한다
      // this.getArticle(this.articleId)

      // !! 넘겨준 파라미터가 있는 경우. 등록시엔 -R을 제거해야 한다.
      const paramIds = to.params.id.split('-')
      if (paramIds.length > 1 && paramIds[1] === 'E') {
        // !! 수정시엔 '-E' 가 붙어서 넘어온다
        this.articleId = Number(paramIds[0])
        // 다시 해당 아이디로 리다이렉트 시킨다.
        this.$router.push(`/${currDir}/${this.articleId}`)
      } else {
        // !! 수정이 아닌 경우
        this.articleId = Number(paramIds[0])
        // 넘어온 id로 해당 게시물 정보를 가져와야 한다
        this.getArticle(this.articleId)
      }
    }
  },

  computed: {
  },

  mounted () {
    // const paramIds = this.$route.params.id.split('-')
    // if (paramIds.length > 1) {
    //   const articleId = parseInt(paramIds[0], 10)
    //   this.getArticle(articleId)
    // } else {
    //   this.getArticle(this.$route.params.id) // 아이디가 넘어온 경우
    // }

    // !! 복잡하지 않고 단순하게 아래처럼.
    this.articleId = parseInt(this.$route.params.id, 10)
    // 넘어온 id로 해당 게시물 정보를 가져와야 한다
    this.getArticle(this.articleId)
  },

  methods: {
    strDateFormat2,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    // 목록으로 - 주의: shop 보내는 것 주의
    goList () {
      this.$router.push(`/${currDir}`)
    },
    // 중요: 리스트를 리프레시 한다.
    refreshList (param = '') {
      this.$router.push(`/${currDir}/${param}`)
    },
    // 구분: id로 게시물을 가져온다
    async getArticle (id) {
      try {
        if (!id) throw new Error('[ERR-0] 잘못된 인수형식 입니다.')

        const { data } = await this.$axios.get(`lawork/eapprove/getEaDetailById/${id}`)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        if (data.article) {
          // !! 현재 결재컨텐츠 매칭
          this.article = data.article
          //
          this.useEditBtn = false // !! '수정'버튼 사용불가. 반려일때 기안자만 사용가능
          //
          // !! 현재 로그인한 유저의 현 결재건에 대한 ealines 정보패칭
          this.userLineInfo = await this.getEalineInfo(id, this.article.depth)
          //
          // !! 현재 로그인한 유저의 결재라인 depth, tern 이 부모인 결재 컨텐츠의 depth, tern 과 일치 하는가?
          if ((this.article.depth === this.userLineInfo.depth) && (this.article.tern === this.userLineInfo.tern)) {
            // 일치하면 로그인 유저가 현재 tern 이므로 '승인','반려','불허' 를 사용 할 수 있다.
            // 하지만 공동결재의 경우 자신은 이미 '승인'을 했을 수 있다. 이 경우 다른 공동결재도 같은 tern 이므로 기다려야 한다.(반려,불허는 아래에서 잡는다.)
            if (this.userLineInfo.result === 1) {
              this.notEditable = true // 이미 승인을 한 경우 '승인','반려','불허' 버튼 사용 불가
            } else if (this.userLineInfo.result === 0) {
              this.notEditable = false // 대기중인 경우 '승인','반려','불허' 버튼 사용가능
            }
          } else {
            // 일치하지 않으면 로그인 유저는 '승인','반려','불허' 버튼을 사용 할 수 없다.
            this.notEditable = true
          }
          //
          // !! 최종승인, 불허, 반려, 오류 처리 - 이외에는 모두 결재중임
          // 중요: this.article.status = 3 [최종승인] || this.article.status = 4 [불허]인 경우
          // > 어떤 조작도 불가함. 댓글도?
          if (this.article.status === 3 || this.article.status === 4) {
            this.notEditable = true // '승인','반려','불허' 버튼을 사용 할 수 없다.
          }
          //
          // 중요: this.article.status = 5 [반려] || this.article.status = 6 [오류]인 경우(시스템 반려)
          // > 이후 기안자는 '수정' 버튼이 활성화 됨
          //   => 클릭시 작성함으로 다시 넘어감. eacontent 의 depth+1 ,tern=1 로 수정
          //   => 기존 결재라인 복사해서 등록(depth+1 한 부모의 것과 맞춤)
          if (this.article.status === 5 || this.article.status === 6) {
            this.notEditable = true // '승인','반려','불허' 버튼을 사용 할 수 없다.
            // !! 기안자의 경우만 '수정' 버튼을 사용할 수 있다.
            if (this.userLineInfo.tern === 1) {
              this.useEditBtn = true
            }
          }
          //
          // 결재자 배열
          this.approvers = data.approvers
          //
          // 회람자 배열
          this.viewers = data.viewers
          //
          // 상태색상적용
          this.currStatusColor = this.statusColors[this.article.status]
          // 상태 아이콘 적용
          this.currStatusIcon = this.statusIcon[this.article.status]

          // !! 2021.2.22 연계데이터가 있고 연계데이터가 삭제된 경우 true
          this.isDelRelation = data.isDelRelation

          // 중요: 서브 컨텐츠인 댓글,첨부파일,공유링크 목록 패칭
          await this.getSubContents(36) // 첨부파일 + 공유링크
          await this.getSubContents(37) // 댓글

          // 중요: 로그인 유저가 속한 팀의 정보를 가져온다. 그중 팀아이디만 따로 this.myTids 배열에 담는다.
          await this.getAllTeamInfoByEmail(this.$store.state.ui.email).then(({ teams }) => {
            if (teams && teams.length > 0) {
              this.myTids = teams.map(t => t.id)
            }
          })

          // !![2021.7.15]연계데이터 정보는 이제 JSON 문자열이므로 파싱해서 처리해야 한다.
          if (this.article.rId && this.article.rType) {
            const tmpInfo = JSON.parse(this.article.rInfo)
            if (this.article.rType === 1) {
              // 소송
              this.relData = `${tmpInfo.caseName} - ${tmpInfo.courtName} ${tmpInfo.caseNum}${tmpInfo.managenum ? `(${tmpInfo.managenum})` : ''}`
            } else if (this.article.rType === 2) {
              // 자문
              this.relData = `${tmpInfo.caseName} - ${tmpInfo.manageNum}`
            } else if (this.article.rType === 3) {
              // 인명부
              this.relData = tmpInfo.name
            }
          }
        } else {
          // throw new Error('존재하지 않는 데이터 입니다!')
          this.isId = false
          this.overlay = true
          setTimeout(this.goList, 1000)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 결재라인 정보 패칭
    async getEalineInfo (pId, depth) {
      try {
        const { data } = await this.$axios.get(`lawork/eapprove/getEalineByPid/${pId}/${depth}`)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        return data.ealine
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 승인버튼 이벤트 핸들러
    async admit () {
      try {
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `결재를 승인 하시겠습니까?`
        if (await pop.open('결재', msg, { color: 'info', width: 400 })) {
          // !! 백앤드로 넘길 다음 결재자 객체를 초기화한다.
          this.userLineInfo.nextInfo = null

          if (this.userLineInfo.isLast) { // !! 로그인 유저가 최종결재자인 경우 > 기안자 정보를 넘긴다
            this.userLineInfo.nextInfo = this.approvers[0].items
            //
          } else { // !! 로그인 유저가 최종결재자가 아닌 경우 > 다음 결재자 정보를 nextInfo로 넘긴다.
            this.userLineInfo.nextInfo = this.approvers[this.userLineInfo.tern].items
            //
          }
          // !! 덧불일 추가 변수
          this.userLineInfo.pInfo = this.article.subject
          this.userLineInfo.pCode = this.article.code ? `` : ''
          this.userLineInfo.eaInfo1 = `${this.article.subject}${this.article.code ? `(${this.article.code})` : ''}` // 제목(내부관리번호)
          this.userLineInfo.eaInfo2 = `${this.article.wname}(${this.$moment(this.article.updatedAt).format('YYYY.MM.DD HH:mm')})` // 기안자이름(상신일)

          // 해당 결재의 승인이므로 result = 1 로 보낸다.
          this.userLineInfo.result = 1
          const { data } = await this.$axios.post(`lawork/eapprove/makeDecision`, this.userLineInfo)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

          if (data.eaContentStatus === 3) {
            // 돌아온 결과가 최종승인
            this.$store.commit('SB_POP', { msg: '결재가 최종승인 되었습니다. 결재함으로 이동합니다.', color: 'success' })
          } else if (data.eaContentStatus === 2) {
            // 그냥 승인으로 리턴받았다.
            this.$store.commit('SB_POP', { msg: '결재가 승인 되었습니다. 다음 단계를 진행합니다.', color: 'success' })
          }

          // !! 리스트 refresh 후 리스트로 이동
          this.refreshList(`${this.articleId}-E`)
          sleep(600).then(() => {
            this.goList()
          })
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 반려버튼 이벤트 핸들러
    async turnBack () {
      try {
        const target = 'confirmWithMsgDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const rObj = await pop.open('반려', '의견개진', { color: 'warning', width: 650 })
        if (rObj.result) {
          // !! 반려시에는 기안자에게 알림/메일을 전달해야 하므로 기안자 정보를 패칭한다.
          this.userLineInfo.nextInfo = this.approvers[0].items

          // !! 덧불일 추가 변수
          this.userLineInfo.pInfo = this.article.subject
          this.userLineInfo.pCode = this.article.code
          this.userLineInfo.eaInfo1 = `${this.article.subject}${this.article.code ? `(${this.article.code})` : ''}` // 제목(내부관리번호)
          this.userLineInfo.eaInfo2 = `${this.article.wname}(${this.$moment(this.article.updatedAt).format('YYYY.MM.DD HH:mm')})` // 기안자이름(상신일)

          // 해당 결재의 반려이므로 result = 2 로 보낸다.
          this.userLineInfo.result = 2
          // 의견을 txt1 필드에 넣는다.
          this.userLineInfo.txt1 = rObj.msg

          const { data } = await this.$axios.post(`lawork/eapprove/makeDecision`, this.userLineInfo)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

          // !! 리스트 refresh 후 리스트로 이동
          this.refreshList(`${this.articleId}-E`)
          this.$store.commit('SB_POP', { msg: '결재가 반려 되었습니다.', color: 'warning' })
          sleep(600).then(() => {
            this.goList()
          })
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 불허버튼 이벤트 핸들러
    async disapprove () {
      try {
        const target = 'confirmWithMsgDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const rObj = await pop.open('불허', '의견개진', { color: 'error', width: 650 })
        if (rObj.result) {
          // !! 불허시에는 기안자에게 알림/메일을 전달해야 하므로 기안자 정보를 패칭한다.
          this.userLineInfo.nextInfo = this.approvers[0].items

          // !! 덧불일 추가 변수
          this.userLineInfo.pInfo = this.article.subject
          this.userLineInfo.pCode = this.article.code
          this.userLineInfo.eaInfo1 = `${this.article.subject}${this.article.code ? `(${this.article.code})` : ''}` // 제목(내부관리번호)
          this.userLineInfo.eaInfo2 = `${this.article.wname}(${this.$moment(this.article.updatedAt).format('YYYY.MM.DD HH:mm')})` // 기안자이름(상신일)

          // 해당 결재의 불허이므로 result = 3 로 보낸다.
          this.userLineInfo.result = 3
          // 의견을 txt1 필드에 넣는다.
          this.userLineInfo.txt1 = rObj.msg

          const { data } = await this.$axios.post(`lawork/eapprove/makeDecision`, this.userLineInfo)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

          // !! 리스트 refresh 후 리스트로 이동
          this.refreshList(`${this.articleId}-E`)
          this.$store.commit('SB_POP', { msg: '결재가 불허 되었습니다.', color: 'error' })
          sleep(600).then(() => {
            this.goList()
          })
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 수정버튼 이벤트 핸들러
    // 반려나 오류로 인해 기안자에게 수정버튼이 보이는 경우다. 수정버튼을 클릭하면 다시 작성함으로 이동하므로 재상신 할 수 있다!
    // 이때 해당 결재의 depth = +1 되는것에 주의!
    async moveEdit () {
      try {
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `결재를 수정 하시겠습니까?<br>수정된 결재는 작성함으로 돌아갑니다.`
        if (await pop.open('결재', msg, { color: 'info', width: 400 })) {
          const form = {
            ealine: this.userLineInfo, // 기안자의 ealines 정보를 넘긴다
            approvers: this.approvers,
            viewers: this.viewers
          }

          const { data } = await this.$axios.post(`lawork/eapprove/remakeEaContent`, form)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          // console.log(data)

          this.$store.commit('SB_POP', { msg: '결재가 재작성 되었습니다. 작성함으로 이동합니다.', color: 'error' })
          sleep(600).then(() => {
            this.redirect(`/eawriting/${this.articleId}`)
          })
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // --- 구분: 히스토리 팝업 ---
    async viewHistoryPop (evt) {
      try {
        // !! 안열린 상태에서만 연다. - 열린상태에서 다시 열지 못하도록
        if (!this.$refs.viewHistoryPop.showMenu) {
          await this.$refs.viewHistoryPop.show(evt) // 일단 열고

          // pId, depth 를 보낸다
          const params = { pId: this.articleId, depth: this.article.depth }
          await this.$refs.viewHistoryPop.setData(params)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // --- 구분: 서브 컨텐츠: 댓글, (첨부파일 + 공유링크) 처리 ---
    // !! 첨부파일(36),공유링크(38) 은 둘중 어느것을 선택하더라도 같이 처리된다. attachedFiles[] 배열에 들어감!
    async getSubContents (type = null) {
      try {
        const columns = { pId: this.articleId, pType: 5, type }
        const { data } = await this.$axios.post(`lawork/eapprove/getSubContents`, columns)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

        // !! 그냥 (첨부파일+공유링크), 댓글로 처리한다.
        if (type === 36 || type === 38) {
          this.attachedFiles = data.list
        } else if (type === 37) {
          this.comments = data.list
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 상세페이지 바로가기
    gotoDetail () {
      // !! 넘어온 rType 에 맞게 소송/자문/인명부/거래처로 이동한다
      // !! 로그인 유저가 소속된 팀을 조사하여 해당 연계정보에 권한이 있는지 확인할 것
      // this.form.rTid - 에는 연계 컨텐츠가 속한 팀 정보가 들어있다.
      if (this.myTids.includes(this.article.rTid)) {
        // 권한이 있다.
        if (this.article.rType && this.article.rId) {
          const typeCheck = {
            1: 'case',
            2: 'advice',
            3: 'client',
            46: 'shop'
          }
          this.$router.push(`/${typeCheck[this.article.rType]}/${this.article.rId}`)
        }
      } else {
        // 권한이 없다!
        const msg = '연계정보에 대한 접근 권한이 없습니다.'
        this.$store.commit('SB_POP', { msg, color: 'error', timeout: 5000, shaped: true })
      }
    },
    // 구분: 로그인 유저의 팀목록 패칭
    async getAllTeamInfoByEmail (email) {
      try {
        const { data } = await this.$axios.get(`admin/staff/getAllTeamInfoByEmail/${email}`)
        if (!data.success) throw new Error(`list error: ${data.message}`)

        return { teams: data.teams }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // --- 구분: 댓글 ---
    // 댓글 팝업 열기
    async showCommentPop (evt) {
      try {
        // !! 안열린 상태에서만 연다. - 열린상태에서 다시 열지 못하도록
        if (!this.$refs.addCommentPop.showMenu) {
          await this.$refs.addCommentPop.show(evt) // 일단 열고

          // !! 나머지 인자를 보낸다.
          // teamId 는 전자결재에선 의미가 없으므로 1로 하드코딩해서 보낸다.
          // pId 는 현재 전자결재의 아이디를 넘긴다.
          const params = { teamId: 1, pId: this.articleId }
          const result = await this.$refs.addCommentPop.setData(params)
          if (result) {
            await this.getSubContents(37) // !! 댓글 목록 리프레시
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 댓글 삭제
    // 주의: 삭제는 case/deleteLwcommon 를 사용한다!
    async deleteComment (commentId) {
      try {
        if (!commentId) throw new Error(`잘못된 요청입니다.`)

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          const { data } = await this.$axios.get(`lawork/case/deleteLwcommon/${commentId}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

          // 정상적으로 등록되었으면 리프레시
          await this.getSubContents(37) // !! 댓글 목록 리프레시
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // --- 구분: 첨부파일 ---
    // 첨부파일 다운로드
    async fileDownload (file) {
      try {
        if (!file.str5) throw new Error(`유효한 파일이 아닙니다.`)
        this.overlay = true // 로딩 오버레이 on
        const webContentLink = file.str5 // 이건 다운로드

        if (this.$store.state.ui.lv === 'M') {
          // !! 관리자인 경우는 그냥 다운로드
          document.location.href = webContentLink // 다운로드 시작!
          // 2초간 로딩 딜레이
          setTimeout(() => {
            this.overlay = false // 로딩 오버레이 off
          }, 2000)
        } else {
          // !! 관리자가 아닌 경우 .. 공유요청
          const formData = {
            fileId: file.str2 // 구글 드라이브 파일 아이디
          }
          const { data } = await this.$axios.post(`lawork/eapprove/requestGdFileShare`, formData)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.permessionId) {
            document.location.href = webContentLink // 다운로드 시작!
            // 2초간 로딩 딜레이
            setTimeout(() => {
              this.overlay = false // 로딩 오버레이 off
            }, 2000)
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 첨부파일 바로보기 - 브라우저에서 바로보기
    async fileDirectView (file) {
      try {
        if (!file.str6) throw new Error(`유효한 파일이 아닙니다.`)
        const webViewLink = file.str6 // 이건 브라우저에서 바로보기

        if (this.$store.state.ui.lv === 'M') {
          // !! 관리자인 경우
          const win = window.open(webViewLink, '_blank')
          win.focus()
        } else {
          // !! 관리자가 아닌 경우 .. 공유요청
          const formData = {
            fileId: file.str2 // 구글 드라이브 파일 아이디
          }
          const { data } = await this.$axios.post(`lawork/eapprove/requestGdFileShare`, formData)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.permessionId) {
            const win = window.open(webViewLink, '_blank')
            win.focus()
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // --- 구분: 공유링크 처리 ---
    // 공유링크 바로가기 처리
    async shareLinkDirectGo (item) {
      try {
        // console.log(item)
        if (!item.str2) throw new Error(`유효한 공유주소가 아닙니다.`)

        // * [2022.6.7 수정] http(s):// 가 없는 경우 자동으로 붙여준다.
        let sLink = item.str2
        let httpRegex = /^(http(s)?:\/\/)/
        if (!httpRegex.test(String(item.str2).toLowerCase())) {
          sLink = 'https://' + sLink
        }

        // 다른 탭에서 링크 열기
        const link = document.createElement('a')
        link.href = sLink
        link.target = '_blank'
        link.click()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: pdf 상세뷰 제너레이터
    // 중요: /src/lib/pdfmakeView.js 파일에서 공통부분을 처리했고 아래 함수에서는 가변적인 부분만 적용하면된다
    async pdfgen () {
      try {
        // !! pdf 파일정보 - 제목만 넣는다 작성자(author)는 제외
        this.pdfDoc.info = {
          title: '전자결재 - 결재함 상세뷰',
          subject: '전자결재 - 결재함 상세뷰'
        }
        // !! 공통스타일 적용 - 따로 하려면 따로 지정하면 된다.
        this.pdfDoc.styles = pdfViewStyle
        this.pdfDoc.defaultStyle = { font: 'Nanum' }

        // @: 헤더 타이틀
        const pdfHeaderTitle = `[${this.article.gubun1}] ${this.article.isStar ? '★ ' : ''} ${this.article.code ? `(${this.article.code})` : ''}${this.article.subject} `

        // @: 작성일 => 최종수정일
        // const workDate = `작성: ${this.$moment().format('YYYY.MM.DD HH:mm')}`
        const workDate = `최종수정일: ${strDateFormat2(this.article.updatedAt)}`

        // 연계정보
        let relInfo = []
        if (this.article.rInfo) {
          relInfo.push([
            {
              text: `${this.article.rInfo} `,
              border: [false, false, false, false]
            }
          ])
        }
        // 첨부파일 + 공유링크
        let attaches = []
        this.attachedFiles.forEach(item => {
          let text = ''
          text = `${item.isAnotherWrite ? ' └▷ ' : ' ┖▶ '}${item.str1} (${strDateFormat2(item.createdAt)} ${item.wname}) `
          attaches.push([ { text, border: [false, false, false, false] } ])
        })

        // 내용
        let rTxt1 = rmTagReturnArray(this.article.content)

        // 결재자
        let rApprovers = []
        this.approvers.forEach(approver => {
          approver.items.forEach(item => {
            let textColor = '#000000'
            let text = ''
            if (this.article.tern === approver.index && this.article.status < 3) {
              text += `√  ` // ◎
              // textColor = '#1976D2'
            }
            text += `${approver.index}) ${item.name} ${item.rank} `
            if (item.tern === 1) {
              text += `[상신] `
            } else {
              text += `[${this.resultTitle[item.result]}] `
            }
            if (item.result !== 0) {
              text += `${strDateFormat2(this.$moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss'))} `
            }

            rApprovers.push([ { text, border: [false, false, false, false], color: textColor } ])
          })
        })

        // 회람자
        let rViewers = []
        this.viewers.forEach(item => {
          let text = ''
          text += `${item.name} ${item.rank} `
          rViewers.push([ { text, border: [false, false, false, false] } ])
        })

        // 의견(댓글)
        let rComments = []
        this.comments.forEach(item => {
          let text = ''
          text = `${item.isAnotherWrite ? ' └▷ ' : ' ┖▶ '}${item.str1} (${strDateFormat2(item.createdAt)} ${item.wname}) `
          rComments.push([ { text, border: [false, false, false, false] } ])
        })

        let content = [
          { text: pdfHeaderTitle, style: 'header' },
          { text: workDate, style: 'subheader' },
          menuTitle('연계정보'),
          viewType2(relInfo),
          menuTitle('첨부파일 + 공유링크'),
          viewType2(attaches),
          menuTitle('내용'),
          viewType1(rTxt1),
          menuTitle('결재자'),
          viewType2(rApprovers),
          menuTitle('회람자'),
          viewType2(rViewers),
          menuTitle('의견'),
          viewType2(rComments)
        ]

        this.pdfDoc.content = content

        // !! 주어진 설정과 함께 pdf 파일을 다른탭에 열기
        pdfMake.createPdf(this.pdfDoc).open()
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style>
/*
  !!참고: 모든 테이블에 공통적용되는 코드지만 각 파일에 있어야 한다.
  새로고침하면 적용이 안되고 적용된 페이지를 들러야 한다.
  v-data-talbe td 의 왼쪽,오른쪽 패딩 제거. 단 style 태그의 scoped 속성을 지워야 적용됨
*/
/* .v-data-table td{ 2020.6.15 변경됨*/
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 0;
  padding-right: 0;
}
.v-overflow-btn .v-select__selection--comma:first-child {
  margin-left: 5px;
  margin-right: 0px;
}
.v-overflow-btn .v-input__append-inner {
  width: 30px;
}
/* 중요: 모바일에서 테이블의 기본 값은 justify-content: space between 이다. 이걸 start 로 변경한다! */
.v-data-table__mobile-row {
  justify-content: start;
}
/*
  참고: 모든 vue2editor 뷰어에 공통적용
*/
#vue2editorViewer p {
  margin-bottom: 0px !important;
}
#vue2editorViewer2 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
#vue2editorViewer3 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
</style>
